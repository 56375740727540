import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './styles/index.css';
import reportWebVitals from './reportWebVitals';

// 1. Import Radix UI theme styles and the Theme component
import { Theme } from '@radix-ui/themes';
import '@radix-ui/themes/styles.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
   <React.StrictMode>
    {/* 2. Wrap your entire App with the <Theme> component */}
    <Theme>
      <App />
    </Theme>
  </React.StrictMode>
);

reportWebVitals();
