import { AudienceRecommendationRecommendationItem } from '@digiseg-labs/api-client';
import { Box, Skeleton } from '@radix-ui/themes';
import { AudienceWithCategory } from '../audience';
import AudienceRecommendationModal from './AudienceRecommendationModal';

export type ConversationItem = {
  sender: 'user' | 'ai';
  message: string;
  recommendations?: AudienceRecommendationRecommendationItem[];
  isLoading?: boolean;
};

type ConversationListProps = {
  conversation: ConversationItem[];
  taxonomy: Map<string, AudienceWithCategory>;
  countries: any[]; // or CountryItem[]
  platforms: any[]; // or AudiencePlatformItem[]
  selectedCountry: any; // or CountryItem
  selectedPlatform: any; // or AudiencePlatformItem
  onCountrySelected: (c: any) => void;
  onPlatformSelected: (p: any) => void;
};

const ConversationList: React.FC<ConversationListProps> = ({
  conversation,
  taxonomy,
  countries,
  platforms,
  selectedCountry,
  selectedPlatform,
  onCountrySelected,
  onPlatformSelected,
}) => {
  return (
    <>
      {conversation.map((msg, index) => {
        const isUser = msg.sender === 'user';
        return (
          <Box
            key={index}
            style={{
              marginTop: '1rem',
              textAlign: isUser ? 'right' : 'left',
            }}
          >
            <strong>{isUser ? 'You:' : 'AI:'}</strong>
            <div style={{ whiteSpace: 'pre-wrap' }}>
              {msg.isLoading ? <Skeleton /> : msg.message}
            </div>

            {/* If AI has recommendations, render them below */}
            {!isUser && !msg.isLoading && msg.recommendations && (
              <div style={{ marginTop: '1rem' }}>
                {msg.recommendations.map((rec, i) => (
                  <div key={i} style={{ marginBottom: '1rem' }}>
                    <AudienceRecommendationModal
                      audienceCode={rec.audience.code}
                      countries={countries}
                      platforms={platforms}
                      taxonomy={taxonomy}
                      selectedCountry={selectedCountry}
                      selectedPlatform={selectedPlatform}
                      onCountrySelected={onCountrySelected}
                      onPlatformSelected={onPlatformSelected}
                    />

                    <p><b>Reason:</b> {rec.reason}</p>
                  </div>
                ))}
              </div>
            )}
          </Box>
        );
      })}
    </>
  );
};

export default ConversationList;