import React, { useState, useEffect } from 'react';
import { Box, Flex, Heading } from '@radix-ui/themes';
import { TaxonomyApi, AudienceRecommendationRecommendationItem } from '@digiseg-labs/api-client';

import ChatInput from './components/ChatInput';
import { useTaxonomy } from './hooks/useTaxonomy';
import ConversationList, { ConversationItem } from './components/ConversationList';

const App: React.FC = () => {
  const [inputValue, setInputValue] = useState('');
  const [conversation, setConversation] = useState<ConversationItem[]>([]);
  const [conversationId, setConversationId] = useState<string | null>(null);

  const [showHeaders, setShowHeaders] = useState(true);
  const [shouldRenderHeaders, setShouldRenderHeaders] = useState(true);
  const [headerAnimation, setHeaderAnimation] =
    useState<'fadeInDown' | 'fadeOutUp'>('fadeInDown');

  // Tracks whether the user has submitted at least one message
  const [hasSubmittedOnce, setHasSubmittedOnce] = useState(false);

  const {
    countries,
    platforms,
    taxonomy,
    selectedCountry,
    selectedPlatform,
    setSelectedCountry,
    setSelectedPlatform,
  } = useTaxonomy();

  // Fade out headers
  useEffect(() => {
    if (!showHeaders) {
      setHeaderAnimation('fadeOutUp');
      const timer = setTimeout(() => {
        setShouldRenderHeaders(false);
      }, 600);
      return () => clearTimeout(timer);
    }
  }, [showHeaders]);

  const handleSubmit = async () => {
    if (!inputValue.trim()) return;

    setShowHeaders(false);

    // Mark that the user has now submitted once
    if (!hasSubmittedOnce) {
      setHasSubmittedOnce(true);
    }

    // 1) Add user message
    setConversation((prev) => [
      ...prev,
      { sender: 'user', message: inputValue.trim() },
    ]);

    // 2) Add a temporary loading AI message
    setConversation((prev) => [
      ...prev,
      { sender: 'ai', message: '', isLoading: true },
    ]);

    try {
      const api = new TaxonomyApi();
      const response = await api.completeAudienceRecommendation({
        message_text: inputValue,
        conversation_id: conversationId ?? undefined,
      });

      if (response.data.data?.conversation_id && !conversationId) {
        setConversationId(response.data.data.conversation_id);
      }

      const message = response.data.data?.message_text || '';
      const recommendations: AudienceRecommendationRecommendationItem[] =
        response.data.data?.recommendations || [];

      // Replace loading message with real AI message
      setConversation((prev) => {
        const newConv = [...prev];
        const loadingIndex = newConv.findIndex(
          (m) => m.sender === 'ai' && m.isLoading
        );
        if (loadingIndex !== -1) {
          newConv[loadingIndex] = {
            sender: 'ai',
            message,
            recommendations,
            isLoading: false,
          };
        }
        return newConv;
      });
    } catch (error) {
      console.error(error);
      // Replace loading message with an error
      setConversation((prev) => {
        const newConv = [...prev];
        const loadingIndex = newConv.findIndex(
          (m) => m.sender === 'ai' && m.isLoading
        );
        if (loadingIndex !== -1) {
          newConv[loadingIndex] = {
            sender: 'ai',
            message: 'Sorry, something went wrong.',
            recommendations: [],
            isLoading: false,
          };
        }
        return newConv;
      });
    } finally {
      setInputValue('');
    }
  };

  return (
    <>
      {/* If user has NOT submitted once, the layout is “normal.” */}
      {!hasSubmittedOnce && (
        <Flex
          direction="column"
          align="center"
          justify="center"
          style={{
            minHeight: '100vh',
            padding: '1rem',
          }}
        >
          <Box style={{ maxWidth: '600px', width: '100%', margin: '0 auto' }}>
            {shouldRenderHeaders && (
              <div className={headerAnimation}>
                <Heading
                  as="h1"
                  size="7"
                  align="center"
                  style={{ marginBottom: '0.5rem' }}
                >
                  What product or service are you selling?
                </Heading>
                <Heading as="h2" size="5" weight="regular" align="center">
                  Find the right audience that suits your needs with our AI.
                </Heading>
              </div>
            )}

            {/* Conversation list at top in normal flow */}
            <ConversationList
              conversation={conversation}
              taxonomy={taxonomy}
              countries={countries}
              platforms={platforms}
              selectedCountry={selectedCountry}
              selectedPlatform={selectedPlatform}
              onCountrySelected={setSelectedCountry}
              onPlatformSelected={setSelectedPlatform}
            />

            {/* Chat input in normal flow */}
            <ChatInput
              inputValue={inputValue}
              onInputValueChange={setInputValue}
              onSubmit={handleSubmit}
            />
          </Box>
        </Flex>
      )}

      {/* After user’s first submit, pinned layout */}
      {hasSubmittedOnce && (
        <>
          {/* Scrollable container pinned top->above pinned input */}
          <Box
            style={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100%',
              bottom: '80px', // space for the pinned input
              overflowY: 'auto',
              backgroundColor: 'white',
            }}
          >
            {/* 
              Removed align="center". 
              This ensures user messages can be right-aligned.
            */}
            <Flex
              direction="column"
              style={{
                maxWidth: '600px',
                margin: '0 auto',
                padding: '1rem',
              }}
            >
              {shouldRenderHeaders && (
                <div className={headerAnimation}>
                  <Heading
                    as="h1"
                    size="7"
                    align="center"
                    style={{ marginBottom: '0.5rem' }}
                  >
                    What product or service are you selling?
                  </Heading>
                  <Heading as="h2" size="5" weight="regular" align="center">
                    Find the right audience that suits your needs with our AI.
                  </Heading>
                </div>
              )}

              <ConversationList
                conversation={conversation}
                taxonomy={taxonomy}
                countries={countries}
                platforms={platforms}
                selectedCountry={selectedCountry}
                selectedPlatform={selectedPlatform}
                onCountrySelected={setSelectedCountry}
                onPlatformSelected={setSelectedPlatform}
              />
            </Flex>
          </Box>

          {/* Chat input pinned at bottom */}
          <Box
            style={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              padding: '1rem',
              backgroundColor: 'white',
              boxShadow: '0 -10px 20px rgba(0,0,0,0.1)',
            }}
          >
            <Box style={{ width: '600px', maxWidth: '90%' }}>
              <ChatInput
                inputValue={inputValue}
                onInputValueChange={setInputValue}
                onSubmit={handleSubmit}
              />
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default App;