import React, { useState } from 'react';
import { ArrowLeftIcon } from '@radix-ui/react-icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  Heading,
  Text,
  TextField,
  Select,
} from '@radix-ui/themes';
import * as Form from '@radix-ui/react-form';
import { AudiencePlatformItem } from '@digiseg-labs/api-client';

type FormComponentProps = {
  onBack: () => void;
  onSubmit?: (data: any) => Promise<void>;
  platforms: AudiencePlatformItem[];

  // now these accept string OR undefined:
  audienceName?: string;
  audienceCategory?: string;
  countrySelected?: string;
};

// Replace with your real webhook
const WEBHOOK_URL = 'https://hook.eu1.make.com/44xnsebbf6d270ih1cx9iku4l9yfbrsg';

const AudienceRequestForm: React.FC<FormComponentProps> = ({
  onBack,
  onSubmit,
  platforms,
  audienceName,
  audienceCategory,
  countrySelected,
}) => {
  // A piece of state to show a success message
  const [successMessage, setSuccessMessage] = useState('');

  return (
    <Form.Root
      onSubmit={async (event) => {
        event.preventDefault();
        setSuccessMessage(''); // clear previous success message if any

        const formElement = event.currentTarget;
        const formData = new FormData(formElement);
        const data = Object.fromEntries(formData);

        // --- DATE VALIDATION (optional) ---
        const startDateInput = formElement.querySelector<HTMLInputElement>(
          'input[name="campaignStartDate"]'
        );
        const endDateInput = formElement.querySelector<HTMLInputElement>(
          'input[name="campaignEndDate"]'
        );
        startDateInput?.setCustomValidity('');
        endDateInput?.setCustomValidity('');

        const startDateValue = data.campaignStartDate as string | undefined;
        const endDateValue = data.campaignEndDate as string | undefined;
        if (startDateValue && endDateValue) {
          const start = new Date(startDateValue);
          const end = new Date(endDateValue);
          if (start > end) {
            endDateInput?.setCustomValidity('endDateCannotBeBeforeStartDate');
          }
        }
        if (!formElement.checkValidity()) {
          formElement.reportValidity();
          return;
        }

        // --- POST to webhook ---
        try {
          // data now includes:
          // - name, email, company
          // - campaignStartDate, campaignEndDate
          // - platformName (the display_name)
          // - audienceName, audienceCategory, countrySelected (from hidden fields below)

          const response = await fetch(WEBHOOK_URL, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(data),
          });
          if (!response.ok) {
            throw new Error(`Webhook Error: ${response.statusText}`);
          }
          console.log('Webhook POST success:', await response.text());
        } catch (err) {
          console.error('Failed to post to webhook:', err);
          // Optionally handle the error or show an error message
        }

        // --- Call parent's onSubmit if provided ---
        if (onSubmit) {
          await onSubmit(data);
        }

        // Finally set success message
        setSuccessMessage('Form submitted successfully!');
      }}
      style={{ marginTop: '1rem' }}
    >
      {/**
       * HIDDEN FIELDS for audience name, category, country. 
       * So they're included in the final form data that we post to the webhook.
       */}
      <Form.Field name="audienceName" style={{ display: 'none' }}>
        <Form.Control asChild>
          <input type="hidden" value={audienceName} />
        </Form.Control>
      </Form.Field>

      <Form.Field name="audienceCategory" style={{ display: 'none' }}>
        <Form.Control asChild>
          <input type="hidden" value={audienceCategory} />
        </Form.Control>
      </Form.Field>

      <Form.Field name="countrySelected" style={{ display: 'none' }}>
        <Form.Control asChild>
          <input type="hidden" value={countrySelected} />
        </Form.Control>
      </Form.Field>

      <Grid columns="2" gap="4">
        {/* Contact Information */}
        <Box>
          <Heading color="gray" as="h3" size="2">
            Contact Information
          </Heading>
          <hr />

          <Form.Field name="name" className="FormField">
            <Box>
              <Form.Label>
                <Text size="1">Name</Text>
              </Form.Label>
              <Form.Message match="valueMissing" className="FormMessage">
                Please enter your name
              </Form.Message>
            </Box>
            <Form.Control asChild>
              <TextField.Root
                className="Input"
                type="text"
                required
                placeholder="Enter your name"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="email" className="FormField" style={{ marginTop: '1rem' }}>
            <Form.Label className="FormLabel">
              <Text size="1">Email</Text>
            </Form.Label>
            <Form.Message match="valueMissing" className="FormMessage">
              Please enter your email
            </Form.Message>
            <Form.Message match="typeMismatch" className="FormMessage">
              Please provide a valid email
            </Form.Message>
            <Form.Control asChild>
              <TextField.Root
                className="Input"
                type="email"
                required
                placeholder="Enter your email"
              />
            </Form.Control>
          </Form.Field>

          <Form.Field name="company" className="FormField" style={{ marginTop: '1rem' }}>
            <Form.Label className="FormLabel">
              <Text size="1">Company</Text>
            </Form.Label>
            <Form.Message match="valueMissing" className="FormMessage">
              Please enter your company name
            </Form.Message>
            <Form.Control asChild>
              <TextField.Root
                className="Input"
                type="text"
                required
                placeholder="Enter your company"
              />
            </Form.Control>
          </Form.Field>
        </Box>

        {/* Campaign Information */}
        <Box>
          <Heading color="gray" as="h3" size="2">
            Campaign Information (Optional)
          </Heading>
          <hr />

          {/* START DATE */}
          <Form.Field name="campaignStartDate" className="FormField">
            <Form.Label className="FormLabel">
              <Text size="1">Campaign Start Date</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root className="Input" type="date" />
            </Form.Control>
          </Form.Field>

          {/* END DATE */}
          <Form.Field name="campaignEndDate" className="FormField" style={{ marginTop: '1rem' }}>
            <Form.Label className="FormLabel">
              <Text size="1">Campaign End Date</Text>
            </Form.Label>

            <Form.Message
              match={(err) => err === 'endDateCannotBeBeforeStartDate'}
              className="FormMessage"
            >
              End date cannot be before start date
            </Form.Message>
            <Form.Control asChild>
              <TextField.Root className="Input" type="date" />
            </Form.Control>
          </Form.Field>

          {/* PLATFORM SELECT - use display_name as the value! */}
          <Form.Field name="platformName" className="FormField" style={{ marginTop: '1rem' }}>
            <Form.Label className="FormLabel">
              <Text size="1">Platform</Text> <br />
            </Form.Label>
            <Form.Control asChild>
              <Select.Root defaultValue="-">
                <Select.Trigger className="Input fullwidth" placeholder="Select a platform" />
                <Select.Content position="popper">
                  <Select.Item value="-">(No platform)</Select.Item>
                  <Select.Group>
                    <Select.Label>Integrated Platforms</Select.Label>
                    {platforms
                      .filter((p) => !p.code.startsWith('request_'))
                      .map((p) => (
                        // The 'value' is p.display_name, so the form captures the name
                        <Select.Item key={p.code} value={p.display_name}>
                          {p.display_name}
                        </Select.Item>
                      ))}
                  </Select.Group>

                  <Select.Group>
                    <Select.Label>Request Based Platforms</Select.Label>
                    {platforms
                      .filter((p) => p.code.startsWith('request_'))
                      .map((p) => (
                        <Select.Item key={p.code} value={p.display_name}>
                          {p.display_name}
                        </Select.Item>
                      ))}
                  </Select.Group>
                </Select.Content>
              </Select.Root>
            </Form.Control>
          </Form.Field>
        </Box>
      </Grid>

      {/* Seat Information */}
      <Box style={{ marginTop: '1rem' }}>
        <Heading color="gray" as="h3" size="2">
          Seat Information (Optional)
        </Heading>
        <hr />
        <Grid columns="2" gap="4">
          <Form.Field name="seatName" className="FormField">
            <Form.Label className="FormLabel">
              <Text size="1">Seat Name</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root className="Input" type="text" placeholder="Enter seat name" />
            </Form.Control>
          </Form.Field>

          <Form.Field name="seatId" className="FormField">
            <Form.Label className="FormLabel">
              <Text size="1">Seat ID</Text>
            </Form.Label>
            <Form.Control asChild>
              <TextField.Root className="Input" type="text" placeholder="Enter seat ID" />
            </Form.Control>
          </Form.Field>
        </Grid>
      </Box>

      {/* Buttons */}
      <Flex gap="2" mt="4">
        <Box>
          <Button variant="soft" type="button" onClick={onBack}>
            <ArrowLeftIcon />
          </Button>
        </Box>
        <Box flexGrow="1">
          <Form.Submit asChild>
            <Button className="fullwidth" color="green">
              Submit
            </Button>
          </Form.Submit>
        </Box>
      </Flex>

      {/** Show a success message if set */}
      {successMessage && (
        <Box mt="3">
          <Text color="green">{successMessage}</Text>
        </Box>
      )}
    </Form.Root>
  );
};

export default AudienceRequestForm;