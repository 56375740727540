import { Flex, TextField, Button } from '@radix-ui/themes';

type Props = {
  inputValue: string;
  onInputValueChange: (value: string) => void;
  onSubmit: () => void;
};

export default function ChatInput({ inputValue, onInputValueChange, onSubmit }: Props) {
  return (
    <Flex direction="row" gap="2" style={{ width: '100%', marginTop: '1rem' }}>
      <TextField.Root
        size="3"
        placeholder="E.G I have a wine shop"
        value={inputValue}
        onChange={(e) => onInputValueChange(e.currentTarget.value)}
        style={{ width: '80%', marginRight: '8px' }}
        // The key line: handle Enter
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            onSubmit();
          }
        }}
      />

      <Button size="3" onClick={onSubmit} style={{ width: '20%' }}>
        →
      </Button>
    </Flex>
  );
}