import React, { useState } from 'react';
import { AudiencePlatformItem, CountryItem } from '@digiseg-labs/api-client';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CopyIcon,
  DesktopIcon,
  HomeIcon,
  PersonIcon,
} from '@radix-ui/react-icons';
import {
  Badge,
  Box,
  Button,
  Card,
  Code,
  Dialog,
  Flex,
  Grid,
  Heading,
  IconButton,
  Select,
  Text,
  Tooltip,
} from '@radix-ui/themes';
import { AudienceWithCategory } from '../audience';
import audImage from '../media/audience.webp';
import AudienceRequestForm from './AudienceRequestForm'; // The separate form component

type Props = {
  audienceCode: string;
  taxonomy: Map<string, AudienceWithCategory>;
  countries: CountryItem[];
  platforms: AudiencePlatformItem[];
  selectedCountry: CountryItem | undefined;
  selectedPlatform: AudiencePlatformItem | undefined;
  onCountrySelected: (c: CountryItem | undefined) => void;
  onPlatformSelected: (p: AudiencePlatformItem | undefined) => void;
};

const AudienceRecommendationModal = (props: Props) => {
  const {
    audienceCode,
    taxonomy,
    countries,
    platforms,
    selectedCountry,
    selectedPlatform,
    onCountrySelected,
    onPlatformSelected,
  } = props;

  const [step, setStep] = useState<number>(1);

  // Check the description exists
  const audienceWithCategory = taxonomy.get(audienceCode);
  const audienceItem = audienceWithCategory?.item;
  const actualDescription = audienceItem?.description?.trim();
  const fallbackDescription = `This is the audience ${audienceItem?.display_name || '(unknown)'
    } from the category ${audienceWithCategory?.category || '(none)'}.`;
  const finalDescription = actualDescription || fallbackDescription;

  // Check if selected platform is "manual" or not
  const isManualPlatform =
    !!selectedPlatform?.code && selectedPlatform.code.startsWith('request_');

  // Check if selected platform is integrated BUT does not have global taxonomy
  const needsCountrySelection =
    selectedPlatform &&
    !isManualPlatform &&
    !selectedPlatform.has_global_taxonomy;

  // Dummy submission handler (replace with your real logic)
  function handleFormSubmit(data: any): Promise<void> {
    console.log('Form submitted with data:', data);
    return Promise.resolve();
  }

  return (
    <Dialog.Root>
      <Dialog.Trigger>
        <Card className="linkcursor shadoweffect" style={{ marginTop: '0.5rem' }}>
          <Flex>
            {/* Left image */}
            <Box width="20%">
              <img
                src={audImage}
                alt="Bold typography"
                style={{
                  display: 'block',
                  objectFit: 'cover',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'var(--gray-5)',
                  borderRadius: '10px',
                }}
              />
            </Box>

            {/* Right info */}
            <Box className="normalpadding" width="80%">
              <Badge size="3">{audienceWithCategory?.category}</Badge>
              <Heading as="h2" size="5">
                {audienceItem?.display_name}
              </Heading>
            </Box>
          </Flex>
        </Card>
      </Dialog.Trigger>

      <Dialog.Content maxWidth="800px">
        <Grid columns="4fr 1fr" gap="4">
          {/* LEFT COLUMN */}
          <Box>
            {/* -- Top row: category + name + country select -- */}
            <Flex gap="2" mb="4">
              <Box width="50%">
                <Badge size="3" color="green">
                  {audienceWithCategory?.category}
                </Badge>
                <Heading>{audienceItem?.display_name}</Heading>
              </Box>

              <Box width="50%">
                <Select.Root
                  value={selectedCountry?.code ?? '-'}
                  onValueChange={(countryCode) => {
                    if (countryCode === '-') {
                      onCountrySelected(undefined);
                      return;
                    }
                    const country = countries.find((c) => c.code === countryCode);
                    onCountrySelected(country);
                  }}
                >
                  <Select.Trigger className="fullwidth" placeholder="Select a country…" />
                  <Select.Content position="popper">
                    <Select.Item value="-">(No Country)</Select.Item>
                    <Select.Group>
                      <Select.Label>Countries</Select.Label>
                      {countries.map((c) => (
                        <Select.Item key={c.code} value={c.code}>
                          <img
                            src={`https://digiseg.io/wp-content/themes/astra-child/images/countryflags/${c.code.toLowerCase()}.png`}
                            alt={c.display_name}
                            style={{ width: 20, marginRight: 8 }}
                          />
                          {c.display_name}
                        </Select.Item>
                      ))}
                    </Select.Group>
                  </Select.Content>
                </Select.Root>
              </Box>
            </Flex>

            {/* -- Body changes based on `step` -- */}
            {step === 1 && (
              <>
                {/* STEP 1: Audience Info */}
                <Flex gap="2" mb="4">
                  <Box width="100%">
                    <Heading color="gray" as="h3" size="2">
                      Description
                    </Heading>
                    <hr />
                    {/* Show either real description or fallback with variables */}
                    <Text>{finalDescription}</Text>
                  </Box>
                </Flex>

                {/* Platform & Code / Reach */}
                <Flex gap="2" mb="4">
                  <Box width="50%">
                    <Heading color="gray" as="h3" size="2">
                      Activate on Platform
                    </Heading>
                    <hr />
                    <Select.Root
                      value={selectedPlatform?.code ?? '-'}
                      onValueChange={(platformCode) => {
                        if (platformCode === '-') {
                          onPlatformSelected(undefined);
                          return;
                        }
                        const platform = platforms.find((p) => p.code === platformCode);
                        if (platform) {
                          onPlatformSelected(platform);
                        }
                      }}
                    >
                      <Select.Trigger className="fullwidth" placeholder="Select a platform…" />
                      <Select.Content position="popper">
                        <Select.Item value="-">(No platform)</Select.Item>
                        <Select.Group>
                          <Select.Label>Integrated Platforms</Select.Label>
                          {platforms
                            .filter((p) => !p.code.startsWith('request_'))
                            .map((p) => (
                              <Select.Item key={p.code} value={p.code}>
                                {p.display_name}
                              </Select.Item>
                            ))}
                        </Select.Group>

                        <Select.Group>
                          <Select.Label>Request Based Platforms</Select.Label>
                          {platforms
                            .filter((p) => p.code.startsWith('request_'))
                            .map((p) => (
                              <Select.Item key={p.code} value={p.code}>
                                {p.display_name}
                              </Select.Item>
                            ))}
                        </Select.Group>
                      </Select.Content>
                    </Select.Root>

                    {/* Only show the "Code" box if NOT manual */}
                    {!isManualPlatform && selectedPlatform && (
                      <Box mt="2">
                        {/** If integrated platform missing country => show message, else show ID */}
                        {!selectedPlatform.has_global_taxonomy && !selectedCountry ? (
                          <Text>Please select a country</Text>
                        ) : (
                          <code>
                            ID: <Code>{audienceWithCategory?.item.platform_code}</Code>{' '}
                          </code>
                        )}
                      </Box>
                    )}
                  </Box>

                  <Box width="50%">
                    <Heading color="gray" as="h3" size="2">
                      Reach
                    </Heading>
                    <hr />

                    {/* Households */}
                    <Tooltip content="Number of Households">
                      <HomeIcon />
                    </Tooltip>
                    <Text ml="2">
                      {selectedCountry
                        ? audienceWithCategory?.item.reach_stats?.num_households?.toLocaleString() ??
                        '–'
                        : '–'}
                    </Text>
                    <br />

                    {/* Persons */}
                    <Tooltip content="Number of Persons">
                      <PersonIcon />
                    </Tooltip>
                    <Text ml="2">
                      {selectedCountry
                        ? audienceWithCategory?.item.reach_stats?.num_persons?.toLocaleString() ??
                        '–'
                        : '–'}
                    </Text>
                    <br />

                    {/* Devices */}
                    <Tooltip content="Number of Devices">
                      <DesktopIcon />
                    </Tooltip>
                    <Text ml="2">
                      {selectedCountry
                        ? audienceWithCategory?.item.reach_stats?.num_devices?.toLocaleString() ??
                        '–'
                        : '–'}
                    </Text>

                    {!selectedCountry && (
                      <>
                        <br />
                        <Text as="p">
                          <em>Please select country to see reach</em>
                        </Text>
                      </>
                    )}
                  </Box>
                </Flex>

                {/* Buttons section */}
                <Box mt="auto">
                  {!isManualPlatform && selectedPlatform ? (
                    <Flex gap="2">
                      <Button
                        variant="outline"
                        onClick={() => {
                          const codeToCopy = audienceWithCategory?.item.platform_code ?? '';
                          navigator.clipboard.writeText(codeToCopy);
                        }}
                      >
                        <CopyIcon width="15" height="15" />
                        &nbsp;Copy ID
                      </Button>
                      <Button style={{ flex: 1 }} onClick={() => setStep(2)}>
                        Request Audience <ArrowRightIcon />
                      </Button>
                    </Flex>
                  ) : (
                    <Button className="fullwidth" onClick={() => setStep(2)}>
                      Request Audience <ArrowRightIcon />
                    </Button>
                  )}
                </Box>
              </>
            )}

            {step === 2 && (
              <>
                {/* STEP 2: Request Form */}
                <AudienceRequestForm
  onBack={() => setStep(1)}
  onSubmit={handleFormSubmit}
  platforms={platforms}
  audienceName={audienceWithCategory?.item.display_name || ''}
  audienceCategory={audienceWithCategory?.category || ''}
  countrySelected={selectedCountry?.display_name || ''}
/>

              </>
            )}
          </Box>

          {/* RIGHT COLUMN */}
          <Box>
            <img
              src={audImage}
              alt="Bold typography"
              style={{
                display: 'block',
                objectFit: 'cover',
                width: '100%',
                height: '100%',
                backgroundColor: 'var(--gray-5)',
              }}
            />
          </Box>
        </Grid>
      </Dialog.Content>
    </Dialog.Root>
  );
};

export default AudienceRecommendationModal;