// hooks/useTaxonomy.ts

import { useEffect, useState } from 'react';
import {
  AudiencePlatformItem,
  CountryItem,
  TaxonomyApi,
} from '@digiseg-labs/api-client';
import { AudienceWithCategory } from '../audience';

export function useTaxonomy() {
  const [countries, setCountries] = useState<CountryItem[]>([]);
  const [platforms, setPlatforms] = useState<AudiencePlatformItem[]>([]);
  const [taxonomy, setTaxonomy] = useState(new Map<string, AudienceWithCategory>());

  const [selectedCountry, setSelectedCountry] = useState<CountryItem | undefined>(undefined);
  const [selectedPlatform, setSelectedPlatform] = useState<AudiencePlatformItem | undefined>(undefined);

  // 1) On mount: load integrated platforms & countries
  useEffect(() => {
    const api = new TaxonomyApi();

    // Load integrated (API) platforms
    api.listAudiencePlatforms()
      .then((resp) => {
        const apiPlatforms = resp.data.data || [];
        apiPlatforms.sort((a, b) => a.display_name.localeCompare(b.display_name));

        // Add your manual “request-based” platforms
        const manualPlatforms: AudiencePlatformItem[] = [
          {
            code: 'request_based_dv360',
            display_name: 'DV360',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_mediasmart',
            display_name: 'Mediasmart',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_basis',
            display_name: 'Basis',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_criteo',
            display_name: 'Criteo',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_bidtheatre',
            display_name: 'BidTheatre',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_amazon',
            display_name: 'Amazon',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_stackadapt',
            display_name: 'StackAdapt',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_quantcast',
            display_name: 'Quantcast',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_amobee',
            display_name: 'Amobee',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_acuityads',
            display_name: 'AcuityAds',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_yahoo',
            display_name: 'Yahoo',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_active_agent',
            display_name: 'Active Agent',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_zeta',
            display_name: 'Zeta',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_zemanta',
            display_name: 'Zemanta',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_mgid',
            display_name: 'MGID',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_delta_projects_azerion',
            display_name: 'Delta Projects / Azerion',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_hawk',
            display_name: 'Hawk',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_beeswax',
            display_name: 'Beeswax',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_adobe',
            display_name: 'Adobe',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_equativ',
            display_name: 'Equativ',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_eskimi',
            display_name: 'Eskimi',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_verve',
            display_name: 'Verve',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_nexxen',
            display_name: 'Nexxen',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_rtb_house',
            display_name: 'RTB House',
            has_global_taxonomy: false,
            supported_countries: [],
          },
          {
            code: 'request_based_viant',
            display_name: 'Viant',
            has_global_taxonomy: false,
            supported_countries: [],
          },
        ];

        // Merge integrated + manual
        const allPlatforms = [...apiPlatforms, ...manualPlatforms];
        setPlatforms(allPlatforms);
      })
      .catch((err) => {
        console.error('Failed to load platforms', err);
      });

    // Load countries
    api.listCountries()
      .then((resp) => {
        const rawCountries = resp.data.data || [];
        rawCountries.sort((a, b) => a.display_name.localeCompare(b.display_name));
        setCountries(rawCountries);
      })
      .catch((err) => {
        console.error('Failed to load countries', err);
      });
  }, []);

  // 2) Load audiences whenever selectedPlatform or selectedCountry changes
  useEffect(() => {
    const api = new TaxonomyApi();

    // Use empty strings to avoid filtering out everything
    // if country/platform is not selected yet
    const platformCode = selectedPlatform?.code || '';
    const countryCode  = selectedCountry?.code  || '';

    api
      .listAudiences(platformCode, countryCode)
      .then((resp) => {
        const audienceSets = resp.data.data || [];
        const categories = audienceSets.flatMap((s) => s.categories);

        const newMap = new Map<string, AudienceWithCategory>();
        categories.forEach((cat) => {
          cat.audiences.forEach((aud) => {
            newMap.set(aud.code, {
              category: cat.display_name,
              item: aud,
            });
          });
        });

        setTaxonomy(newMap);
      })
      .catch((err) => {
        console.error('Failed to load audiences', err);
      });
  }, [selectedPlatform, selectedCountry]);

  return {
    // Data
    countries,
    platforms,
    taxonomy,

    // Current user selections
    selectedCountry,
    selectedPlatform,

    // Setters
    setSelectedCountry,
    setSelectedPlatform,
  };
}